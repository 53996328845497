<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">Tentang Kami</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-3 mb-4">
					<SidebarPanduan />
				</div>
				
				<div class="col-md-9 mb-4">
					<div class="card shadow border-0 text-secondary round px-5 py-4">
						<div class="text-center mb-4">
							<h2>Tentang Kami</h2>
							<img src="@/assets/images/tentang-kami.jpg" alt="tentang-kami.jpg" class="w-75 round shadow">
						</div>
						<div class="text-left">
							<p>Duniamurah.com  merupakan website e-commerce resmi dari PT. Putra Perdana Sentosa yang telah berpengalaman dalam penyediaan kebutuhan berbagai produk alat listrik dan teknik serta jasa  selama lebih dari 10 tahun. Sesuai perkembangan saat ini, sejak tahun 2020 Duniamurah.com juga mulai melakukan penjualan bahan Makanan ,  Minuman dan produk kebutuhan kantor. </p>
							<p>Dengan pengalaman tersebut, Duniamurah.com berkeyakinan dapat menyediakan kebutuhan pelanggan meliputi kebutuhan alat-alat listrik - Teknik - Bahan Makanan , Minuman dan kebutuhan kantor .Disamping itu dengan dukungan staf yang berpengalaman serta dukungan teknologi terdepan menjadikan Duniamurah.com sebagai solusi terbaik dalam penyediaan kebutuhan pelanggan.</p>
							<p>Duniamurah.com bekerjama dengan berbagai jasa pengiriman yang terpercaya dalam proses pengiriman barang ke pelanggan. Untuk area DKI Jakarta, DuniaMurah.com menggunakan pengiriman sendiri yaitu divisi Duniakurir.</p>
							<p>Peralatan yang dijual di Duniamurah.com mendapatkan garansi resmi dari principal dan garansi dari Duniamurah.com sendiri.</p>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import SidebarPanduan from '@/components/SidebarPanduan.vue'

export default {
	components: {
  		SidebarPanduan
	},
	created() {
		$(document).scrollTop(0)
	} 
}
</script>